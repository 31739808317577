import type { NextPage } from 'next';
import Image from 'next/image';
import { Box, Typography, Stack } from '@mui/material';
import noData from '../../public/images/no-data-icon.svg';

interface Props {
  text: string;
}

const EmptyData: NextPage<Props> = ({ text }) => {
  return (
    <Stack height='100%' alignItems='center' justifyContent='center' className='u-w-100'>
      <Box
        className='u-flex u-flex-justify-center u-flex-align-center u-w-64-px u-h-64-px u-br-50'
        sx={{ background: '#D9D9D9', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
      >
        <Image
          src={noData}
          alt='No results'
        />
      </Box>
      <Typography className='fs-title-large u-fw-700'>Empty</Typography>
      <Typography className='fs-body-regular'>{text}</Typography>
    </Stack>
  );
};

export default EmptyData;
