import { useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useGlobalState } from '../../context/globalState';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { Box, Skeleton } from '@mui/material';
import EmptyData from '../emptyData/emptyData';

export const MarkdownRenderer = ({fileName}: {fileName: string}) => {
  const { velocityName } = useGlobalState();
  const [markdownContent, setMarkdownContent] = useState<string | null>(null);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (velocityName) {
      (async function iife () {
        try {
          setIsLoading(true);
          const res: AxiosResponse = await axios.get(`/api/markdown?org=${velocityName}&fileName=${fileName}`);
          if (!res.data) {
            setIsEmpty(true);
          }
          const html = marked(res.data, { headerIds: false, mangle: false, langPrefix: undefined });
          const cleanContent = DOMPurify.sanitize(html);
          setMarkdownContent(cleanContent);
          setIsLoading(false);
        } catch (error) {
          if (!axios.isCancel(error)) {
            setIsEmpty(true);
            setIsLoading(false);
          }
        }
      })();
    }
  }, [fileName, velocityName]);

  return (
    <>
      {isLoading ? (
        Array.from(Array(20).keys()).map((value: number) => (
          <Skeleton
            key={value + 1}
            variant='rectangular'
            height={30}
            className='u-mb-6'
          />
        ))
      ) : (
        isEmpty || !markdownContent ? (
          <EmptyData text='Sorry, there is no data available.' />
        ) : (
          <Box
            className='markdown-content'
            dangerouslySetInnerHTML={{ __html: markdownContent }}
          />
        )
      )}
    </>
  );
};

export default MarkdownRenderer;