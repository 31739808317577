import type { NextPage } from 'next';
import { Snackbar, Alert, IconButton, LinearProgress } from '@mui/material';
import { useUiState } from '../../context/uiState';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import NotificationImportantRoundedIcon from '@mui/icons-material/NotificationImportantRounded';

const Toast: NextPage = () => {
  const { snackbarProps, setSnackbarProps } = useUiState();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarProps({ ...snackbarProps, open: false, customIcon: false });
  };

  return (
    <Snackbar
      open={snackbarProps.open}
      onClose={handleClose}
      message={snackbarProps.message}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{ top: '50px !important' }}
    >
      <Alert
        variant='outlined'
        severity={snackbarProps.type}
        action={
          <>
            <IconButton
              aria-label='close'
              color='default'
              sx={{ p: 0.5 }}
              onClick={handleClose}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
        iconMapping={{
          success: !snackbarProps.customIcon ? <CheckCircleOutlineRoundedIcon /> : <NotificationImportantRoundedIcon />
        }}
      >
        {snackbarProps.message}
        <LinearProgress
          variant='determinate'
          value={100}
          color={snackbarProps.type}
          sx={{ borderBottomLeftRadius: '3px', borderBottomRightRadius: '3px' }}
        />
      </Alert>
    </Snackbar>
  );
};

export default Toast;
