import type { NextPage } from 'next';
import { CircularProgress } from '@mui/material';

interface Props {
  transparentBackground?: boolean;
}

const Loading: NextPage<Props> = ({ transparentBackground }) => (
  <div className={!transparentBackground ? 'spinner' : 'loader'}>
    <CircularProgress className='circular-progress' size={70} />
  </div>
);

export default Loading;
