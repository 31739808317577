import type { NextPage } from 'next';
import { Drawer, Box, Typography } from '@mui/material';
import CloseButton from '../closeButton/closeButton';
import useWindowSize from '../../utils/useWindowSize';
import MarkdownRenderer from '../markdownRenderer/markdownRenderer';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const PeopleDrawer: NextPage<Props> = ({ open, setOpen }) => {
  const { height } = useWindowSize();

  return (
    <Drawer
      open={open}
      anchor='right'
      onClose={() => setOpen(false)}
      elevation={4}
      PaperProps={{
        sx: { borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px', maxWidth: '600px', width: '100%' }
      }}
      ModalProps={{
        BackdropProps: {
          classes: {
            root: 'backdrop-bg'
          }
        }
      }}
    >
      <Box className='u-py-20 u-px-20 u-relative u-h-100 drawer-content-wrapper'>
        <Box className='u-flex u-flex-space-between u-sticky drawer-title-wrapper'>
          <Typography variant='h4' className='u-pt-12 u-text-align-left'>People Definitions</Typography>
          <CloseButton handleCloseModal={() => setOpen(false)} />
        </Box>
        <Box className='drawer-subtitle-wrapper u-px-12 u-mt-24 u-py-16'>
          <Typography sx={{ fontSize: '20px' }}>Total number of contacts, for all organizations</Typography>
        </Box>
        <Box
          className='u-py-32 u-w-100 u-flex u-flex-direction-column markdown-wrapper'
          sx={{ height: height - 80, minHeight: '600px' }}
        >
          <MarkdownRenderer
            fileName='contacts.md'
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default PeopleDrawer;
