import type { NextPage } from 'next';
import { useUiState } from '../../context/uiState';
import { useExportState } from '../../context/exportState';
import { Box, Collapse, Typography, CircularProgress, Tooltip } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';

interface Props {
  setOpenCancelModal: (value: boolean) => void;
  layoutPadding: boolean;
}

const FileExport: NextPage<Props> = ({ setOpenCancelModal, layoutPadding }) => {
  const { isExportDropdownOpen, setIsExportDropdownOpen } = useUiState();
  const { requestedExports } = useExportState();

  return (
    <Box className='export-wrapper' sx={layoutPadding ? { transform: 'translateX(-20px)' } : {}}>
      <Box className='u-flex u-flex-space-between u-flex-align-center u-py-12 u-px-20 export-header-wrapper'>
        <Typography className='export-header-text'>Preparing export...</Typography>
        <Box className='u-flex u-flex-align-center'>
          <ExpandLessRoundedIcon
            onClick={() => setIsExportDropdownOpen(!isExportDropdownOpen)}
            sx={{ color: '#fff', marginRight: '25px', cursor: 'pointer' }}
          />
          <Tooltip title='Cancel' placement='bottom' classes={{ tooltip: 'custom-tooltip' }}>
            <CloseRoundedIcon
              onClick={() => setOpenCancelModal(true)}
              sx={{ color: '#fff', cursor: 'pointer' }} fontSize='small'
            />
          </Tooltip>
        </Box>
      </Box>
      <Collapse in={isExportDropdownOpen}>
        <Box sx={{ background: '#fff' }}>
          {requestedExports.length > 0 ? requestedExports.map((value: { name: string, id: string, progress: number }) => (
            <Box className='u-flex u-flex-space-between u-flex-align-center u-py-16 u-pl-16 u-pr-12' key={value.id}>
              <Typography className='export-item-text'>Data export being prepared for download</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <CircularProgress
                  size={16}
                  variant='determinate'
                  value={100}
                  sx={{ color: '#cacaca', position: 'absolute', top: 0, left: 0 }}
                  thickness={4}
                />
                <CircularProgress
                  size={16}
                  value={+(value.progress * 100).toFixed(2)}
                  variant='determinate'
                  sx={{ color: '#00B5B0' }}
                  thickness={4}
                />
              </Box>
            </Box>
          )) : null}
        </Box>
      </Collapse>
    </Box>
  );
};

export default FileExport;
